<template>
  <div>
    <vs-card>
      <vs-table ref="table" pagination :max-items="20" search :data="projects">
        <template slot="thead">
          <vs-th>Código</vs-th>
          <vs-th>Descrição</vs-th>
          <vs-th>Valor SAP</vs-th>
          <vs-th>Valor de Produção</vs-th>
          <vs-th>Imagem</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                {{ tr.code }}
              </vs-td>
              <vs-td>
                {{ tr.name }}
              </vs-td>
              <vs-td> R$ {{ Number(tr.sapValue).toFixed(2) }} </vs-td>
              <vs-td>
                <span v-if="tr.productionValue"
                  >R$ {{ Number(tr.productionValue).toFixed(2) }}</span
                >
              </vs-td>
              <vs-td>
                <vs-button
                  @click="showScreenImage(tr.image)"
                  type="flat"
                  icon="photo_camera"
                  closable
                  v-if="tr.image"
                ></vs-button
              ></vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
      <vs-popup :active.sync="showImage" title="Imagem do Projeto" fullscreen>
        <img :src="image" title="Imagem do Projeto" />
      </vs-popup>
    </vs-card>
  </div>
</template>

  <script>
import siriusAPI from "../services";
import { notifyError } from "@/utils/helpers";
import { formatDateToLocale } from "@/utils/helpers";
export default {
  data() {
    return {
      projects: [],
      showImage: false,
      image: null,
    };
  },

  mounted() {
    this.loadProjects();
  },

  methods: {
    showScreenImage(image) {
      this.showImage = true;
      this.image = process.env.VUE_APP_URL_IMAGES + image;
    },
    loadProjects() {
      this.$vs.loading();
      new siriusAPI()
        .getProjects()
        .then((res) => {
          this.projects = res.data;
          this.projects = this.projects.map((project) => ({
            ...project,
            createdDate: formatDateToLocale(project.createdAt),
          }));
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar a lista de projetos " + error
          );
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
  },
};
</script>
  <style scoped>
.radio-space {
  padding-right: 0.5em;
}
ul {
  margin-bottom: 0.8em;
}
</style>
